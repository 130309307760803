/* globals yall */
import { preloadJson, renderVoucherBanner } from "./ui.js";
import { initPaymentIcons } from "./payment-icons.js";
import { initCookieConsent } from "./consent.js";
import { trackHeaderLinkClicks } from "./analytics.js";
import initReadMore from "./read-more.js";
import { initSearchBar } from "./search-bar";
import { initMainMenu } from "./components/MainMenu";
import { initStickyHeader } from "./sticky-header";
export default function initPage() {
    var currentCountry = window.currentCountry, currentLang = window.currentLang;
    if (!sessionStorage.getItem("currentLang") ||
        !sessionStorage.getItem("currentCountry")) {
        sessionStorage.setItem("currentLang", currentLang);
        sessionStorage.setItem("currentCountry", currentCountry);
    }
    initPaymentIcons();
    preloadJson();
    renderVoucherBanner();
    initCookieConsent();
    trackHeaderLinkClicks();
    initReadMore();
    initSearchBar();
    initStickyHeader();
    initMainMenu();
}
